import './resume.css';

export default function Resume() {
    return (
		<div className="resumeGrid">
			<div className="resumeGrid__item">
				<div className="resumeGrid__item__content">
					<h2>Resume</h2>
					<div className="resumeGrid__item__content__education">
						<h3>Education</h3>
						<div className="resumeGrid__item__content__education__item">
							<strong>Lincoln Memorial University</strong>
							<br />
							<a
								href="https://www.lmunet.edu/school-of-business/academics/graduate-programs/master-of-business-administration/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Master of Business Administration
							</a>
							<span className="label__inprogress">In Progress</span>
							<p>Technology and Project Concentration</p>
							<em>2024 - 2025</em>
						</div>
						<div className="resumeGrid__item__content__education__item">
							<strong>King University</strong>
							<br />
							<a
								href="https://www.king.edu/programs/business/information-technology/"
								target="_blank"
								rel="noopener noreferrer"
							>
								B.S. in Information Technology
							</a>
							<p>Cyber Security Management Track - 4.0 with Distinction</p>
							<em>2022 - 2023</em>
						</div>
						<div className="resumeGrid__item__content__education__item">
							<strong>Northeast State Community College</strong>
							<br />
							<a
								href="https://catalog.northeaststate.edu/preview_program.php?catoid=28&poid=6378"
								target="_blank"
								rel="noopener noreferrer"
							>
								Associates of Applied Science in Information Technology
							</a>
							<p>Computer Programming Concentration - Summa Cum Laude</p>
							<em>2020 - 2022</em>
						</div>
					</div>
					<div className="resumeGrid__item__content__education">
						<h3>Experience</h3>
						<div className="resumeGrid__item__content__education__item">
							<a href="https://tricitiesmediagroup.com/" target="_blank" rel="noopener noreferrer">
								<strong>Tricites Media Group</strong>
							</a>
							<p>
								Solo Agency / Freelance
								<span className="label__inprogress">Currently</span>
							</p>
							<p>Self-employed contractor working with a variety of clients on web, backend, mobile, and desktop projects</p>
							<em>2018 - present</em>
						</div>
						<div className="resumeGrid__item__content__education__item">
							<a href="https://soulheart.co/" target="_blank" rel="noopener noreferrer">
								<strong>Soulheart</strong>
							</a>
							<p>
								Contract Full Stack Developer
								<span className="label__inprogress">Currently</span>
							</p>
							<p>Providing full stack software development services in Typescript, Python, PHP, and JavaScript</p>
							<em>2022 - present</em>
						</div>
						<div className="resumeGrid__item__content__education__item">
							<a href="https://visualmediachurch.com/" target="_blank" rel="noopener noreferrer">
								<strong>Visual Media Labs</strong>
							</a>
							<p>
								Contract Community Manager providing technology advice and helping community members troubleshoot broadcast
								systems
							</p>
							<em>2020 - 2022</em>
						</div>
					</div>
				</div>
			</div>
			<div className="resumeGrid__item">
				<div className="resumeGrid__item__content">
					<h3>I'm looking for...</h3>
					<ul>
						<li>Remote or local (East TN) Employment</li>
						<li>Contracting or Freelance Work</li>
					</ul>
				</div>
			</div>
			<div className="resumeGrid__item">
				<div className="resumeGrid__item__content">
					<h3>Volunteering</h3>
					<div className="resumeGrid__item__content__volunteering__item">
						<strong>Congressional App Challenge</strong>
						<p>Judge of Student Submissions</p>
						<em>2023</em>
					</div>
					<div className="resumeGrid__item__content__volunteering__item">
						<strong>Association of Computing Machinery</strong>
						<p>Chapter Vice Chair and Secretary</p>
						<em>2021 - 2022</em>
					</div>
					<div className="resumeGrid__item__content__volunteering__item">
						<strong>Phi Theta Kappa</strong>
						<p>Chapter Vice President of Communications</p>
						<em>2021 - 2022</em>
					</div>
					<div className="resumeGrid__item__content__volunteering__item">
						<strong>Startup Mountain Summit</strong>
						<p>Regional economic development conference production volunteer</p>
						<em>October 2023</em>
					</div>
					<div className="resumeGrid__item__content__volunteering__item">
						<strong>Samaritan's Purse</strong>
						<p>Operation Christmas Child Volunteer</p>
						<em>Annual</em>
					</div>
					<div className="resumeGrid__item__content__volunteering__item">
						<strong>Apostolic Gospel Academy</strong>
						<p>Volunteered to teach students fundamentals of computer science</p>
						<em>2021 - 2022</em>
					</div>
				</div>
			</div>
			<div
				className="resumeGrid__item"
				style={{
					backgroundImage: `url("./roman-synkevych-vXInUOv1n84-unsplash.jpg")`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
				}}
			>
				<p>
					Photo by{' '}
					<a href="https://unsplash.com/@synkevych?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
						Roman Synkevych
					</a>
				</p>
			</div>
			<div className="resumeGrid__item">
				<div className="resumeGrid__item__content">
					<h3>Awards and Honors</h3>
					<div className="resumeGrid__item__content__awards__item">
						<strong>2022 and 2023 Best Local Digital Agency</strong>
						<p>Kingsport Times News</p>
					</div>
					<div className="resumeGrid__item__content__awards__item">
						<strong>2022 Coca-Cola Academic Team Gold Scholar</strong>
						<p>Phi Theta Kappa Honor Society</p>
					</div>
					<div className="resumeGrid__item__content__awards__item">
						<strong>2021-2022 Computer Programming Program Award</strong>
						<p>Northeast State Community College</p>
					</div>
					<div className="resumeGrid__item__content__awards__item">
						<strong>Silver Medalist</strong>
						<p>Tennessee SkillsUSA Programming Competition</p>
					</div>
				</div>
			</div>
			<div className="resumeGrid__item">
				<div className="resumeGrid__item__content">
					<h3>Certifications</h3>
					<div className="resumeGrid__item__content__certifications__item">
						<strong>Testout Security Pro</strong>
						<p>Cyber security certification</p>
					</div>
					<div className="resumeGrid__item__content__certifications__item">
						<strong>Cloudflare Zaraz Certified Developer</strong>
						<p>Issued by Cloudflare after case study submission</p>
					</div>
					<div className="resumeGrid__item__content__certifications__item">
						<strong>sUAS Remote Pilot License</strong>
						<p>FAA Part 107 commercial drone pilot</p>
					</div>
					<div className="resumeGrid__item__content__certifications__item">
						<strong>Heartsaver First Aid CPR AED</strong>
						<p>American Heart Association</p>
					</div>
					<div className="resumeGrid__item__content__certifications__item">
						<strong>Mental Health First Aid USA</strong>
						<p>Mental Health First Aid</p>
					</div>
					<div className="resumeGrid__item__content__certifications__item">
						<strong>Open Water Diver</strong>
						<p>PADI Scuba Certified</p>
					</div>
				</div>
			</div>
			<div className="resumeGrid__item">
				<div className="resumeGrid__item__content">
					<h3>Skills and Tech Stack</h3>
					<div className="skills">
						<img
							src="https://img.shields.io/badge/-VS_Code-grey?style=flat&logo=visual-studio-code&logoColor=blue"
							alt="Visual Studio Code logo"
							title="Visual Studio Code"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-Postman-grey?style=flat&logo=postman"
							alt="Postman logo"
							title="Postman"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-Notion-grey?style=flat&logo=notion&logoColor=black"
							alt="Notion logo"
							title="Notion"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-WordPress-grey?logo=wordpress"
							alt="WordPress logo"
							title="WordPress"
							height="25"
						/>
						<img src="https://img.shields.io/badge/-Ghost-grey?logo=ghost" alt="Ghost logo" title="Ghost" height="25" />
						<img src="https://img.shields.io/badge/-Sanity-grey?logo=sanity" alt="Sanity logo" title="Sanity" height="25" />
						<img
							src="https://img.shields.io/badge/-Kali-grey?logo=kalilinux&logoColor=%23fff"
							alt="Kali logo"
							title="Kali Cloud"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-Adobe_Creative_Cloud-grey?style=flat&logo=adobe-creative-cloud&logoColor=%23DA1F26"
							alt="Adobe Creative Cloud logo"
							title="Adobe Creative Cloud"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-Cloudflare-grey?style=flat&logo=cloudflare&logoColor=%23F38020"
							alt="Cloudflare logo"
							title="Cloudflare"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-Firebase-grey?style=flat&logo=firebase&logoColor=%23FFCA28"
							alt="Firebase logo"
							title="Firebase"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-AWS-grey?style=flat&logo=amazon-aws&logoColor=white"
							alt="AWS logo"
							title="AWS"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-Digital_Ocean-grey?style=flat&logo=digitalocean"
							alt="Digital Ocean logo"
							title="Digital Ocean"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-Google_Cloud-grey?style=flat&logo=googlecloud"
							alt="Google Cloud logo"
							title="Google Cloud"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-Cloudways-grey?style=flat&logo=cloudways&logoColor=%232C39BD"
							alt="Cloudways logo"
							title="Cloudways"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-Azure-grey?style=flat&logo=microsoftazure"
							alt="Azure logo"
							title="Azure"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-Vercel-grey?style=flat&logo=vercel"
							alt="Vercel logo"
							title="Vercel"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-JavaScript-grey?logo=javascript"
							alt="JavaScript logo"
							title="JavaScript"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-TypeScript-grey?logo=typescript&logoColor=%233178C6"
							alt="TypeScript logo"
							title="TypeScript"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-C%23-grey?logo=csharp&logoColor=%23239120"
							alt="C# logo"
							title="C#"
							height="25"
						/>
						<img src="https://img.shields.io/badge/-PHP-grey?logo=php" alt="PHP logo" title="PHP" height="25" />
						<img
							src="https://img.shields.io/badge/-Java-grey?logo=oracle&logoColor=%23F80000"
							alt="Java logo"
							title="Java"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-Rust-grey?logo=rust&logoColor=white"
							alt="Rust logo"
							title="Java"
							height="25"
						/>
						<img
							src="https://img.shields.io/badge/-Python-grey?logo=python&logoColor=%233776AB"
							alt="Python logo"
							title="Python"
							height="25"
						/>
						<img src="https://img.shields.io/badge/-HTML-grey?logo=html5" alt="HTML logo" title="HTML" height="25" />
						<img src="https://img.shields.io/badge/-CSS-grey?logo=csswizardry" alt="CSS logo" title="CSS" height="25" />
						<img src="https://img.shields.io/badge/-SQL-grey?logo=mysql" alt="SQL logo" title="SQL" height="25" />
						<img src="https://img.shields.io/badge/-React-grey?logo=react" alt="React logo" title="React" height="25" />
						<img
							src="https://img.shields.io/badge/-Next.js-grey?logo=nextdotjs"
							alt="Next.js logo"
							title="Next.js"
							height="25"
						/>
						<img src="https://img.shields.io/badge/-Svelte-grey?logo=svelte" alt="Svelte logo" title="Svelte" height="25" />
						<img src="https://img.shields.io/badge/-.NET-grey?logo=dotnet" alt=".NET logo" title=".NET" height="25" />
						<img src="https://img.shields.io/badge/-MAUI-grey?logo=dotnet" alt="MAUI logo" title="MAUI" height="25" />
						<img
							src="https://img.shields.io/badge/-CSS_Modules-grey?logo=cssmodules"
							alt="CSS Modules logo"
							title="CDD Modules"
							height="25"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
import './hero.css';

export default function Hero() {
	return (
		<div className="heroGrid">
			<div className="heroGrid__item">
				<div className="heroGrid__item__content">
					<h1>
						Mackenly Jones
						<br />
						Fullstack Software Engineer
					</h1>
					<p>
						Hello, I'm Mackenly Jones,
						<img src="./MJones-47 - Small.jpg" width="40px" alt="Mackenly Jones" className="inline-image" />
						I'm a full-stack polyglot software
						<img src="./code.jpg" width="60px" alt="Image of decorative code" className="inline-image" />
						engineer on a mission to reshape modern communication one
						<img src="./key.jpg" width="60px" alt="Decorative image of a keyboard" className="inline-image" />
						line at a time. 👨‍💻
					</p>
					<div className="heroCtaButtons">
						<a
							href="https://www.linkedin.com/in/mackenly/"
							target="_blank"
							rel="noopener noreferrer"
							className="heroCtaButtons__item__link"
						>
							Contact Me
						</a>
						<a
							href="https://github.com/mackenly"
							target="_blank"
							rel="noopener noreferrer"
							className="heroCtaButtons__item__github"
						>
							<img src="./github-icon.svg" width="20px" alt="Github logo" />
						</a>
					</div>
				</div>
			</div>
			<div
				className="heroGrid__item"
				style={{
					backgroundImage: `url("./MJones-47 - Small.jpg")`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
				}}
			/>
			<div className="heroGrid__item heroForbesQuote">
				<div className="heroGrid__item__content">
					<p>
						Featured in: Adjusting Plans, Refining A Niche, Reigniting Enthusiasm: Unafraid Entrepreneurs Innovate in a Changed
						World
						<a
							href="https://www.forbes.com/advisor/personal-finance/gen-z-entrepreneurs-innovate-in-a-changed-world-4-small-business-owners-share-their-insights/"
							rel="noopener noreferrer"
							target="_blank"
						>
							{' '}
							Read article→
						</a>
					</p>
					<img src="./forbes-logo.png" width="70px" alt="Forbes Logo" />
				</div>
			</div>
			<div className="heroGrid__item heroVita">
				<div className="heroGrid__item__content">
					<h2>Vita</h2>
					<p>
						I'm a full stack developer on a mission to reshape modern communication one line at a time. <br />
						<span>📝Listen, 💬Talk, 👨‍💻Code.</span>
					</p>
				</div>
			</div>
		</div>
	);
}

import Container from '../container'
import './footer.css'

export default function Footer() {
  return (
		<footer className="footer-contain">
			<Container>
				<div className="footer-flex">
					<h3 className="text-4xl lg:text-5xl font-bold tracking-tighter leading-tight text-center lg:text-left mb-10 lg:mb-0 lg:pr-4 lg:w-1/2">
						© {new Date().getFullYear()} Mackenly Jones
					</h3>
					<div className="footer-buttons">
						<a href="https://x.com/mackenlyjones" className="footer-button-bolded">
							Follow me on 𝕏 (Twitter)
						</a>
						<a href={`https://github.com/mackenly`} className="footer-button">
							My GitHub
						</a>
					</div>
				</div>
			</Container>
		</footer>
  );
}

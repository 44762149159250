import Alert from './alert/alert';
import Footer from './footer/footer';

export default function Layout({ children }) {
	return (
		<>
			<div className="min-h-screen">
				{/*<Alert />*/}
				<main>{children}</main>
			</div>
			<Footer />
		</>
	);
}

import './App.css';
import Container from './components/container';
import Hero from './components/hero/hero';
import Resume from './components/resume/resume';
import Layout from './components/layout';

function App() {
	return (
		<div className="App">
			<Layout>
				<Container>
					<Hero />
					<Resume />
					<p
						style={{
							paddingTop: '50px',
							paddingBottom: '10vh',
							color: '#333',
							textAlign: 'center',
						}}
					>
						Here are a few quick links to my stuff...
					</p>
					<ul className="temp_link_list">
						<li>
							<a
								href="
                https://www.linkedin.com/in/mackenly"
								target="_blank"
								rel="noopener noreferrer"
							>
								LinkedIn
							</a>
						</li>
						<li>
							<a
								href="
                https://twitter.com/mackenlyjones"
								target="_blank"
								rel="noopener noreferrer"
							>
								Twitter
							</a>
						</li>
						<li>
							<a
								href="
                https://github.com/mackenly"
								target="_blank"
								rel="noopener noreferrer"
							>
								GitHub
							</a>
						</li>
						<li>
							<a
								href="
                https://tricitiesmediagroup.com/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Tricities Media Group (Business)
							</a>
						</li>

						<li>
							<a
								href="
                https://crtv.dev/"
								target="_blank"
								rel="noopener noreferrer"
							>
								CRTV DEV (Tech Blog)
							</a>
						</li>
						<li>
							<a
								href="
                https://thechurchfactory.com/"
								target="_blank"
								rel="noopener noreferrer"
							>
								The Church Factory (Marketing Blog)
							</a>
						</li>
					</ul>
				</Container>
			</Layout>
		</div>
	);
}

export default App;
